import * as firebase from "firebase/app";
import 'firebase/firestore';
import "firebase/auth";
import "firebase/analytics";

const app = firebase.initializeApp({
  apiKey: "AIzaSyBMDCEZjOXDv6fHtRbjz6T3F2glM1uismo",
  authDomain: "empro-now.firebaseapp.com",
  databaseURL: "https://empro-now.firebaseio.com",
  projectId: "empro-now",
  storageBucket: "empro-now.appspot.com",
  messagingSenderId: "293310952034",
  appId: "1:293310952034:web:50905a7bd12db4643dd73b",
  measurementId: "G-8YHHJSLTRQ"
});

// added analytics to the project
const defaultAnalytics = firebase.analytics();

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const twitterProvider = new  firebase.auth.TwitterAuthProvider();
export const githubProvider = new  firebase.auth.GithubAuthProvider();
export const db =  firebase.firestore();

export default app;
