import React, { Fragment, useState, useEffect } from 'react';
import man from '../../../assets/images/dashboard/user.png'
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';

const UserPanel = () => {
    const [profile, setProfile] = useState('');
    const [email, setEmail] = useState('sin@email.com');
    const [displayName, setDisplayName]  = useState('Empro Bot');
    
    useEffect(() => {
        setProfile(localStorage.getItem('profileURL') || man);
        setEmail(localStorage.getItem('email' || 'sin@email.com'));
        setDisplayName(localStorage.getItem('displayName' || 'Empro Bot'));
    }, []);

    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div>
                    <img className="img-60 rounded-circle lazyloaded blur-up" src={profile ? profile : man} alt="#" />
                    <div className="profile-edit">
                        {/*<Link to={`${process.env.PUBLIC_URL}/users/userEdit`}>*/}
                        <Link href="_blank" to={`https://myaccount.google.com/?utm_source=OGB&tab=mk&utm_medium=act&gar=1`}>
                            <Edit />
                        </Link>
                    </div>
                </div>
                <h6 className="mt-3 f-14">{displayName}</h6>
                <p>{email}</p>
            </div>
        </Fragment>
    );
};

export default UserPanel;